<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="checkForm"
    aria-autocomplete="off"
  >
    <v-row>
      <p>
        {{ $t('message.security_policy_text') }}
      </p>

      <v-col cols="12" sm="12" md="12">
        <p v-if="successMessage">
          <v-alert
            text
            dense
            color="teal"
            border="left"
            type="success"
          >
            {{ successMessage }}
          </v-alert>
        </p>
        <p v-if="errors.length">
          <v-alert
            text
            dense
            type="error"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <v-text-field
          v-model="hiddenField"
          label="hiddenField"
          placeholder=""
          class="hidden"
        ></v-text-field>

        <v-text-field
          v-model="newPassword"
          :rules="newPasswordRules"
          @input="passwordCheck"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          :label="$t('message.new_password')"
          autocomplete="new-password"
        ></v-text-field>

        <div class="d-flex">
          <span class="mr-3 font-weight-bold text-danger" style="color: darkred;">{{ $t('message.weak') }}</span>
          <password
            v-model="newPassword"
            :secureLength="10"
            :strength-meter-only="true"
          />
          <span class="ml-3 font-weight-bold text-success" style="color: darkred;">{{ $t('message.strong') }}</span>
        </div>

        <span class="font-weight-bold">{{ $t('message.minimal_password_level') }}</span><br>
        <span class="frmValidation" :class="{'frmValidation--passed' : newPassword.length > 9 && newPassword.length <= 32}"><i class="frmIcon fas" :class="newPassword.length > 9 && newPassword.length <= 32 ? 'fa-check' : 'fa-times'"></i> {{ $t('message.between_10_32_char') }}</span><br>
        <span class="frmValidation" :class="{'frmValidation--passed' :hasUppercase }"><i class="frmIcon fas" :class="hasUppercase ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_caps') }}</span><br>
        <span class="frmValidation" :class="{'frmValidation--passed' :hasLowercase }"><i class="frmIcon fas" :class="hasLowercase ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_lowercase') }}</span><br>
        <span class="frmValidation" :class="{'frmValidation--passed' : hasNumber }"><i class="frmIcon fas" :class="hasNumber ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_digit') }}</span><br>
        <span class="frmValidation" :class="{'frmValidation--passed' : hasSpecialChar }"><i class="frmIcon fas" :class="hasSpecialChar ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_special_char') }}</span><br>

        <v-text-field
          v-model="confirmNewPassword"
          :rules="confirmNewPasswordRules"
          class="mt-2"
          :label="$t('message.confirm_new_password')"
          :type="showPassword ? 'text' : 'password'"
          autocomplete="new-password"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <button type="submit" :disabled="!valid" class="btn btn-centered" :class="valid ? 'btn-primary' : ''">{{ $t('message.reset_password') }}</button>

      <router-link to="/connexion" class="recovery mt-10 text-left">{{ $t('message.back_to_login') }}</router-link>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import Password from 'vue-password-strength-meter'
import LoginLayout from '../../layouts/LoginLayout'
import config from '../../config'

export default {
  name: 'UpdateCurrentPassword',
  components: { Password },
  data: () => ({
    valid: false,
    showPassword: false,
    successMessage: null,
    errors: [],
    hiddenField: '',
    newPassword: '',
    confirmNewPassword: '',
    hasNumber: false,
    hasLowercase: false,
    hasUppercase: false,
    hasSpecialChar: false
  }),
  created () {
    this.$emit('update:layout', LoginLayout)
    document.title = config.title + ' - ' + this.$t('message.update_current_password')
  },
  computed: {
    newPasswordRules () {
      return [
        v => !!v || this.$t('message.mandatory_password')
      ]
    },
    confirmNewPasswordRules () {
      return [
        v => !!v || this.$t('message.mandatory_password_confirmation')
      ]
    }
  },
  methods: {
    checkForm () {
      const user = this.$store.getters.getUser

      if (this.newPassword !== this.confirmNewPassword) {
        this.errors = [{ title: this.$t('message.password_must_match') }]

        return
      }

      if (this.newPassword.toLowerCase() === user.username.toLowerCase()) {
        this.errors = [{ title: this.$t('message.password_must_be_different_email') }]

        return
      }

      if (this.newPassword.length < 10 || !this.hasNumber || !this.hasLowercase || !this.hasUppercase || !this.hasSpecialChar) {
        this.errors.push({ title: this.$t('message.password_not_strong_enough') })

        return
      }

      if (this.valid) {
        this.updatePassword()
      }
    },
    passwordCheck () {
      this.hasNumber = /\d/.test(this.newPassword)
      this.hasLowercase = /[a-z]/.test(this.newPassword)
      this.hasUppercase = /[A-Z]/.test(this.newPassword)
      this.hasSpecialChar = /[!@#\$%\^\&*\)\(+=._-]/.test(this.newPassword)
    },
    updatePassword () {
      const user = this.$store.getters.getUser
      const userId = user.userId

      const data = {
        newPassword: this.newPassword,
        confirmNewPassword: this.confirmNewPassword
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/update-current-password/' + userId, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.successMessage = data
              this.errors = []

              setTimeout(() => {
                const formData = new FormData()
                formData.append('email', user.username.toLowerCase())
                formData.append('password', this.newPassword)

                this.$store.dispatch('login', { formData })
              }, 1000)
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          this.loading = false
        })
    }
  }
}
</script>
